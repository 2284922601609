@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://cdn.jsdelivr.net/npm/fonts-archive-vladimir-script/VladimirScript.css');
@font-face {
  font-family: 'Vladimir Script';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('https://cdn.jsdelivr.net/npm/fonts-archive-vladimir-script/VladimirScript.woff2') format('woff2'),
       url('https://cdn.jsdelivr.net/npm/fonts-archive-vladimir-script/VladimirScript.woff') format('woff'),
       url('https://cdn.jsdelivr.net/npm/fonts-archive-vladimir-script/VladimirScript.ttf') format('truetype');
}

.card-title {
    margin-top: 20px;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .card-img-top {
    max-width: 200px;
    height: 200px;
  }
  
  .button {
    margin-top: 20px;
  }

  .ant-drawer-content-wrapper{
    width: 800px;
    
  }

  .drawer-img {
    max-width: 50%;
    height: auto;
    margin-bottom: 20px;
  }
 
  /* logo */
  .cls-1 {
    fill: #a4c00b;
  }

  .cls-1, .cls-2 {
    stroke-width: 0px;
  }

  .cls-2 {
    fill: #4964c9;
  }


  

  